import React from 'react';
import classnames from 'classnames';
import colorbar from 'assets/images/colorbar_jet.png';
import {
  CircularProgress,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AppContext } from 'App';
import OpacitySlider from 'components/Slider/OpacitySlider';

const WaterLevelPlot = React.lazy(() => import('./WaterLevelPlot'));
const legendStyle = { fontSize: 10, fontWeight: 500 };

const spanStyle = makeStyles({
  root: {
    color: '#0b4566',
    cursor: 'pointer',
    '&:hover': {
      color: '#00a4ec',
    },
  },
  highlight: {
    color: '#00a4ec',
    textDecoration: 'underline',
  },
});

const values = ['10 ℃', '15 ℃', '20 ℃', '25 ℃', '30 ℃', '35 ℃'];

const TemperatureStat = () => {
  const {
    actions: { dispatchOpacity },
    state: { kommunes },
  } = React.useContext(AppContext);

  const [year, setYear] = React.useState(2020);
  const classes = spanStyle();
  const onChangeCommitted = val => {
    dispatchOpacity({ type: 'SET_TEMPERATURE_OPACITY', opacity: val / 100 });
  };
  return (
    <Box>
      <Typography variant="body2">
        The data presented here provides aggregate statistics, at district
        level, of the land surface temperature data. Click on the districts in
        the map to compare the average temperatures between districts, during
        the summers of 2019 and 2020.
      </Typography>

      <Box pt={1} width={1} display="flex" justifyContent="space-between">
        <Typography variant="subtitle1">
          {!kommunes || kommunes.length === 0
            ? 'Average summer temperature'
            : 'Temperature per district'}
        </Typography>
        <Box>
          <Typography variant="subtitle1">
            <span
              className={classnames({
                [classes.root]: true,
                [classes.highlight]: year === 2019,
              })}
              onClick={() => setYear(2019)}
            >
              2019{' '}
            </span>
            /{' '}
            <span
              className={classnames({
                [classes.root]: true,
                [classes.highlight]: year === 2020,
              })}
              onClick={() => setYear(2020)}
            >
              2020
            </span>
          </Typography>
        </Box>
      </Box>

      <Box height={240} width={1}>
        <React.Suspense fallback={<CircularProgress />}>
          <WaterLevelPlot year={year} />
        </React.Suspense>
      </Box>

      <Box pt={2}>
        <Typography variant="subtitle1">Temperature legend:</Typography>
      </Box>
      <img
        src={colorbar}
        alt="colorbar"
        style={{ width: '100%', height: 6, borderRadius: 3 }}
      />
      <Box width={1} display="flex" justifyContent="space-between">
        {values &&
          values.map(val => (
            <Box key={val}>
              <Typography style={legendStyle}>{val}</Typography>
            </Box>
          ))}
      </Box>
      <Box mb={1} />
      <OpacitySlider getValue={onChangeCommitted} />
    </Box>
  );
};

export default TemperatureStat;
