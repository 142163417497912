import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { AppContext } from 'App';

const OpticalButtonStyle = { minWidth: 72 };
const numberStyle = { minWidth: 12 };
const months = [5, 6, 7, 8];

const ButtonDaily = ({ handleChange, selectedMonth, selectMonth }) => {
  const {
    state: { monthNames, opticalData },
  } = React.useContext(AppContext);

  //   const [selectedMonth, selectMonth] = React.useState();

  const classes = buttonStyle();

  return (
    <>
      <Box width={1} py={1}>
        <Typography variant="subtitle1">Select daily data:</Typography>
      </Box>
      <Box width={1} pb={selectedMonth && 1} display="flex" flexWrap="wrap">
        {months &&
          months.map(month => (
            <Box
              key={month}
              className={classnames({
                [classes.semiButton]: true,
                [classes.semiActive]: month === selectedMonth,
              })}
              style={OpticalButtonStyle}
              onClick={() =>
                month === selectedMonth ? selectMonth() : selectMonth(month)
              }
            >
              <Typography variant="body2">
                {monthNames[month] + " '20"}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box width={1} display="flex" flexWrap="wrap">
        {opticalData &&
          opticalData.map(
            ({ active, category, title }, i) =>
              category === 'nrt' &&
              parseInt(title.split('-')[1]) === selectedMonth && (
                <Box
                  key={title + i}
                  className={classnames({
                    [classes.button]: true,
                    [classes.active]: active,
                  })}
                  style={numberStyle}
                  onClick={() => handleChange(i)}
                >
                  <Typography variant="body2">{title.split('-')[2]}</Typography>
                </Box>
              )
          )}
      </Box>
    </>
  );
};

export default ButtonDaily;
