import React from 'react';
import Map from 'components/Map';
import Sidebar from 'components/Sidebar';
import SidebarControl from 'components/SidebarControl';
import { Box } from '@material-ui/core';
import {
  useWindowDimensions,
  opacityDefaults,
  opacityReducer,
} from 'data/utils';
import {
  getAerosolData,
  getLstData,
  getNdviData,
  getOpticalData,
} from 'data/defaultProducts';

import { kommuneColors, monthNames } from 'data/defaultData';

export const AppContext = React.createContext();

const App = () => {
  const { width } = useWindowDimensions();
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [activeProducts, setActiveProducts] = React.useState();
  const [isOpticalBasemap, setIsOpticalBasemap] = React.useState(false);
  const [aerosolData, setAerosolData] = React.useState();
  const [lstData, setLstData] = React.useState();
  const [ndviData, setNdviData] = React.useState();
  const [opticalData, setOpticalData] = React.useState();

  const [kommunes, setKommunes] = React.useState();

  const [opacityState, dispatchOpacity] = React.useReducer(
    opacityReducer,
    opacityDefaults
  );

  React.useEffect(() => {
    const getData = async (callFunc, setState, optical) => {
      try {
        let arr = await callFunc();
        arr[0].active = true;
        if (optical) {
          arr[0].active = false;
          arr[43].active = true;
        }
        setState(arr);
      } catch (error) {
        console.error(error);
      }
    };

    getData(getAerosolData, setAerosolData);
    getData(getLstData, setLstData);
    getData(getNdviData, setNdviData);
    getData(getOpticalData, setOpticalData, true);
  }, []);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [openDrawer]);

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          activeProducts,
          aerosolData,
          kommuneColors,
          kommunes,
          lstData,
          monthNames,
          ndviData,
          openDrawer,
          opticalData,
          isOpticalBasemap,
          opacityState,
        },
        actions: {
          handleToggleDrawer,
          setActiveProducts,
          setAerosolData,
          setKommunes,
          setLstData,
          setNdviData,
          setOpticalData,
          setIsOpticalBasemap,
          dispatchOpacity,
        },
      }}
    >
      <div style={{ width: '100vw', height: window.innerHeight }}>
        {width > 600 ? (
          <Box display="flex" height={1} width={1}>
            <Box flexGrow={1}>
              <Map mobile={false} />
            </Box>
            {openDrawer && (
              <Box width={360} style={{ borderLeft: '1px solid #DBE4E9' }}>
                <Sidebar />
              </Box>
            )}
            <Box width={36} style={{ borderLeft: '1px solid #DBE4E9' }}>
              <SidebarControl />
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" height={1} width={1}>
            <Box width={1} flexGrow={1}>
              <Map mobile={true} />
            </Box>
            {openDrawer && (
              <Box height={'50%'} width={1}>
                <Sidebar />
              </Box>
            )}
            <Box height={36} style={{ borderTop: '1px solid #DBE4E9' }}>
              <SidebarControl mobile={true} />
            </Box>
          </Box>
        )}
      </div>
    </AppContext.Provider>
  );
};

export default App;
