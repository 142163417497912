import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { format, parse } from 'date-fns';
import { AppContext } from 'App';

const ndviButtonStyle = {
  minWidth: 72,
};

const ButtonGroupNdvi = () => {
  const {
    actions: { setNdviData },
    state: { ndviData },
  } = React.useContext(AppContext);

  const classes = buttonStyle();

  const handleChange = i => {
    let obj = ndviData;
    obj.forEach(button => (button.active = false));
    obj[i].active = !obj[i].active;
    setNdviData([...obj]);
  };

  return (
    <Box width={1} pb={1} display="flex" flexWrap="wrap">
      {ndviData &&
        ndviData.map(({ active, title }, i) => (
          <Box
            key={title + i}
            className={classnames({
              [classes.button]: true,
              [classes.active]: active,
            })}
            style={ndviButtonStyle}
            onClick={() => handleChange(i)}
          >
            <Typography variant="body2">
              {format(parse(title, 'yyyy-MM-dd', new Date()), 'MMM yyyy')}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default ButtonGroupNdvi;
