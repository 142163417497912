import React from 'react';
import { Layer } from 'react-map-gl';
import { AppContext } from 'App';

const ImperviousBuildupLayer = () => {
  const {
    state: { opacityState },
  } = React.useContext(AppContext);
  return (
    <Layer
      {...{
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [
            'https://oupqp5gj9b.execute-api.eu-central-1.amazonaws.com/development/singleband/cph/eo/composite/2018-01-01/imperviousbuiltup/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map=' +
              encodeURIComponent(
                JSON.stringify({ '0': 'fffdf2', '1': 'FD3F75' })
              ),
          ],
          tileSize: 256,
        },
        paint: { 'raster-opacity': opacityState.build_density_opacity },
      }}
    />
  );
};

export default ImperviousBuildupLayer;
