import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { AppContext } from 'App';

const OpticalButtonStyle = { minWidth: 58 };

const ButtonAnnual = ({ handleChange }) => {
    const {
        state: { opticalData },
      } = React.useContext(AppContext);

  const classes = buttonStyle();

  return (
    <>
      <Box width={1} py={1}>
        <Typography variant="subtitle1">Select annual data:</Typography>
      </Box>
      <Box width={1} pb={1} display="flex" flexWrap="wrap">
        {opticalData &&
          opticalData.map(
            ({ active, category, title }, i) =>
              category === 'annual' && (
                <Box
                  key={title + i}
                  className={classnames({
                    [classes.button]: true,
                    [classes.active]: active,
                  })}
                  style={OpticalButtonStyle}
                  onClick={() => handleChange(i, true)}
                >
                  <Typography variant="body2">{title.split('-')[0]}</Typography>
                </Box>
              )
          )}
      </Box>
    </>
  );
};

export default ButtonAnnual;
