import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PublicIcon from '@material-ui/icons/Public';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { iconStyle } from 'styles/global';
import { AppContext } from 'App';

const ZoomControl = ({ setZoom, zoom }) => {
  const classes = iconStyle();
  const {
    state: { isOpticalBasemap },
    actions: { setIsOpticalBasemap },
  } = React.useContext(AppContext);

  const gridStyle = {
    position: 'fixed',
    left: 0,
    top: '50%',
    width: 36,
    boxShadow: 'rgba(0, 0, 0, 0.16) 4px 0px 4px',
    zIndex: 100,
    transform: 'translate(0, -50%)',
  };
  return (
    <Grid container style={gridStyle}>
      <Grid container>
        <Tooltip placement="right" title={'Change base map'}>
          <IconButton
            className={classes.button}
            onClick={() => setIsOpticalBasemap(!isOpticalBasemap)}
          >
            <PublicIcon
              className={!isOpticalBasemap ? classes.icon : classes.activeIcon}
            />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container>
        <IconButton
          className={classes.button}
          onClick={() => setZoom(zoom + 1)}
        >
          <AddIcon className={classes.icon} />
        </IconButton>
      </Grid>
      <Grid container>
        <IconButton
          className={classes.button}
          onClick={() => setZoom(zoom - 1)}
        >
          <RemoveIcon className={classes.icon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ZoomControl;
