import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { titleStyle } from 'styles/global';
import logo from 'assets/images/DHI_Logo_Blue.png';

export default function Title() {
  const classes = titleStyle();
  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item xs={9}>
          <Typography className={classes.titleMain} style={{ fontWeight: 500 }}>
            Satlas Urban
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <a href="https://eo4society.esa.int/eo_clinic/">
            <img src={logo} alt={'eo clinic logo'} style={{ width: 36 }} />
          </a>
        </Grid>
      </Grid>
      <Box />
      <Typography className={classes.subTitle} style={{ fontSize: 12 }}>
        Satellite-based monitoring of Copenhagen
      </Typography>
    </Box>
  );
}
