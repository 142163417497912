import React from 'react';
import ButtonGroupLst from './ButtonGroupLst';
import colorbar from 'assets/images/colorbar_jet.png';
import { Box, Typography } from '@material-ui/core';
import OpacitySlider from 'components/Slider/OpacitySlider';
import { AppContext } from 'App';
const legendStyle = {
  fontSize: 10,
  fontWeight: 500,
};

const values = ['10 ℃', '15 ℃', '20 ℃', '25 ℃', '30 ℃', '35 ℃'];

const Temperature = () => {
  const {
    actions: { dispatchOpacity },
  } = React.useContext(AppContext);

  const onChangeCommitted = val => {
    dispatchOpacity({ type: 'SET_LST_OPACITY', opacity: val / 100 });
  };
  return (
    <Box>
      <Typography variant="body2">
        Land surfaces temperatures (LST) are estimations of temperatures at
        surface level, based on data collected from satellites carrying thermal
        infrared bands. This data is frequently used in urban settings,
        particularly for the purpose of identifying and measuring urban heat
        islands. The data presented here is based on Landsat 8 data and presents
        LST from selected dates during the spring and summer of 2020.
      </Typography>
      <Box py={1}>
        <Typography variant="subtitle1">Select date:</Typography>
      </Box>
      <ButtonGroupLst />
      <Box py={1}>
        <Typography variant="subtitle1">Temperature legend:</Typography>
      </Box>
      <img
        src={colorbar}
        alt="colorbar"
        style={{ width: '100%', height: 6, borderRadius: 3 }}
      />
      <Box width={1} display="flex" justifyContent="space-between">
        {values &&
          values.map(val => (
            <Box key={val}>
              <Typography style={legendStyle}>{val}</Typography>
            </Box>
          ))}
      </Box>
      <Box mb={1} />
      <OpacitySlider getValue={onChangeCommitted} />
    </Box>
  );
};

export default Temperature;
