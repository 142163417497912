import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { linkStyle } from 'styles/global';

const Description = () => {
  const [details, showDetails] = React.useState(false);
  const classes = linkStyle();

  return (
    <>
      <Typography variant="h6">Description</Typography>
      <Box p={2}>
        <Typography variant={'body2'} paragraph>
          Monitoring the state and dynamics of Copenhagen using the latest
          satellite technology.
        </Typography>
        <Typography variant={'body2'}>
          This application presents various satellite-based products which can
          be used to monitor the current state, as well as short- and long-term
          variation and change, of various environmental, climatic, and urban
          parameters in Copenhagen.{' '}
          <span
            className={classnames({
              [classes.link]: true,
              [classes.close]: details,
            })}
            onClick={() => showDetails(!details)}
          >
            {details ? 'Hide details.' : 'Read more.'}
          </span>
        </Typography>
        {details && (
          <>
            <Box mt={1}>
              <Typography variant={'body2'} paragraph>
                Use the panels below to access the <b>Data products</b> which
                contains satellite imagery from 1984-2020, in addition to three
                parameters derived from Copernicus Sentinel data. The{' '}
                <b>Copernicus High Resolution Layers</b> contains three relevant
                products from the 2018 series of Pan-European High Resolution
                Layers (HRL) from the Copernicus Land Monitoring Service. The
                <b> District statistics</b> contains aggregated statistics on
                temperature variation, by district, over the summers 2019 and
                2020.
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography variant={'body2'} paragraph>
                The data presented contains modified Copernicus Sentinel data
                2020.
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Description;
