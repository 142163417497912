import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { linkStyle } from 'styles/global';
import colorbar from 'assets/images/colorbar_rdpu.png';
import OpacitySlider from 'components/Slider/OpacitySlider';
import { AppContext } from 'App';

const ImperviousDensity = () => {
  const {
    actions: { dispatchOpacity },
  } = React.useContext(AppContext);
  const styles = linkStyle();

  const onChangeCommitted = val => {
    dispatchOpacity({ type: 'SET_IMP_DENSITY_OPACITY', opacity: val / 100 });
  };
  return (
    <Box display="flex" flexWrap="wrap">
      <Typography variant="body2">
        The HRL Imperviousness 2018 product from the Copernicus Land Monitoring
        Service (CLMS) provides a synoptic view of the impermeable cover of soil
        as well as built-up areas (new in the 2018 production). The data
        presented here provides a synoptic view of the degree of imperviousness
        (0-100%) in Copenhagen.
      </Typography>
      <Box mt={0.5}>
        <Typography variant="body2">
          &#169; European Union, Copernicus Land Monitoring Service 2020,
          European Environment Agency (EEA)
        </Typography>
      </Box>

      <Box py={1}>
        <Typography variant="subtitle1">Imperviousness degree:</Typography>
      </Box>
      <img
        src={colorbar}
        alt="colorbar"
        style={{ width: '100%', height: 6, borderRadius: 3 }}
      />
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td className={styles.legend} style={{ textAlign: 'left' }}>
              0 %
            </td>
            <td className={styles.legend} style={{ textAlign: 'right' }}>
              100 %
            </td>
          </tr>
        </tbody>
      </table>
      <Box mt={1} style={{ width: '100%' }}>
        <OpacitySlider getValue={onChangeCommitted} />
      </Box>
    </Box>
  );
};

export default ImperviousDensity;
