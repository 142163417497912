import React from 'react';
import { Layer } from 'react-map-gl';
import { AppContext } from 'App';

const AerosolLayer = () => {
  const {
    state: { aerosolData, opacityState },
  } = React.useContext(AppContext);

  return (
    aerosolData &&
    aerosolData.map(
      ({ active, data }, i) =>
        active && (
          <Layer
            key={i}
            {...{
              type: 'raster',
              source: {
                type: 'raster',
                tiles: [data],
                tileSize: 256,
              },
              paint: { 'raster-opacity': opacityState.aerosol_opacity },
            }}
          />
        )
    )
  );
};

export default AerosolLayer;
