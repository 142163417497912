import React from 'react';
import DataProducts from './DataProducts';
import Description from './Description';
import Title from './Title/Title';
import { Paper } from '@material-ui/core';

import 'styles/scrollbar.css';

const Sidebar = () => {
  return (
    <Paper
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <Title />
      <Description />
      <DataProducts />
    </Paper>
  );
};

export default Sidebar;
