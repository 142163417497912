import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AirQuality from './partials/AirQuality';
import Grassland from './partials/Grassland';
import Greenness from './partials/Greenness';
import ImperviousBuildup from './partials/ImperviousBuildup';
import ImperviousDensity from './partials/ImperviousDensity';
import SatelliteImagery from './partials/SatelliteImagery';
import Temperature from './partials/Temperature';
import TemperatureStat from './partials/TemperatureStat';

import { AppContext } from 'App';
import DHITheme from 'styles/theme';

const products = [
  {
    name: 'Data products',
    prod: [
      { title: 'Satellite imagery', comp: SatelliteImagery, id: 0 },
      { title: 'Greenness', comp: Greenness, id: 1 },
      { title: 'Land surface temperature', comp: Temperature, id: 2 },
      { title: 'Air quality', comp: AirQuality, id: 3 },
    ],
  },
  {
    name: 'COPERNICUS HIGH RESOLUTION LAYERS',
    prod: [
      { title: 'Grassland', comp: Grassland, id: 4 },
      { title: 'Imperviousness density', comp: ImperviousDensity, id: 5 },
      { title: 'Impervious built-up', comp: ImperviousBuildup, id: 6 },
    ],
  },
  {
    name: 'District statistics',
    prod: [{ title: 'Temperature', comp: TemperatureStat, id: 7 }],
  },
];

const DataProducts = () => {
  const {
    actions: { setActiveProducts },
    state: { activeProducts },
  } = React.useContext(AppContext);
  const { primary } = DHITheme.palette;

  return (
    products &&
    products.map(({ name, prod }, i) => (
      <React.Fragment key={name}>
        <Typography variant="h6" style={{ borderTop: i > 0 ? 'none' : 'auto' }}>
          {name}
        </Typography>
        {prod &&
          prod.map(({ id, title, comp: Component }) => (
            <Accordion key={id} expanded={activeProducts === id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: primary.main }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                onClick={() =>
                  activeProducts === id
                    ? setActiveProducts()
                    : setActiveProducts(id)
                }
              >
                <Typography variant="h4" style={{ fontSize: 12 }}>
                  {title}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Component />
              </AccordionDetails>
            </Accordion>
          ))}
      </React.Fragment>
    ))
  );
};

export default DataProducts;
