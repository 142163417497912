import React from 'react';
import { Layer } from 'react-map-gl';
import { AppContext } from 'App';

const NdviLayer = () => {
  const {
    state: { ndviData, opacityState },
  } = React.useContext(AppContext);

  return (
    ndviData &&
    ndviData.map(
      ({ active, data }, i) =>
        active && (
          <Layer
            key={i}
            {...{
              type: 'raster',
              source: {
                type: 'raster',
                tiles: [data],
                tileSize: 256,
              },
              paint: { 'raster-opacity': opacityState.greenness_opacity },
            }}
          />
        )
    )
  );
};

export default NdviLayer;
