import React from 'react';
import classnames from 'classnames';
import ButtonAnnual from './ButtonAnnual';
import ButtonDaily from './ButtonDaily';
import ButtonMonthly from './ButtonMonthly';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { AppContext } from 'App';

const defaultButtons = ['Annual', 'Monthly', 'Daily'];
const OpticalButtonStyle = { minWidth: '30%' };

const ButtonGroupOptical = () => {
  const {
    actions: { setOpticalData },
    state: { opticalData },
  } = React.useContext(AppContext);

  const [selectedMonth, selectMonth] = React.useState();
  const [category, setCategory] = React.useState('Monthly');
  const classes = buttonStyle();

  const handleChange = (i, clear) => {
    let obj = opticalData;
    obj.forEach(button => (button.active = false));
    obj[i].active = !obj[i].active;
    setOpticalData([...obj]);
    clear && selectMonth();
  };

  return (
    <>
      <Box width={1} py={1}>
        <Typography variant="subtitle1">Choose your date range:</Typography>
      </Box>
      {defaultButtons &&
        defaultButtons.map(button => (
          <Box
            key={button}
            className={classnames({
              [classes.button]: true,
              [classes.active]: button === category,
            })}
            style={OpticalButtonStyle}
            onClick={() =>
              button === category ? setCategory() : setCategory(button)
            }
          >
            <Typography variant="body2">{button}</Typography>
          </Box>
        ))}

      {category === 'Annual' && <ButtonAnnual handleChange={handleChange} />}
      {category === 'Monthly' && <ButtonMonthly handleChange={handleChange} />}
      {category === 'Daily' && (
        <ButtonDaily
          handleChange={handleChange}
          selectedMonth={selectedMonth}
          selectMonth={selectMonth}
        />
      )}
    </>
  );
};

export default ButtonGroupOptical;
