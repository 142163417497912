import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import OpacitySlider from 'components/Slider/OpacitySlider';
import { AppContext } from 'App';

const legend = [
  { title: 'No impervious built-up', color: '#fffdf2' },
  { title: 'Impervious built-up', color: '#FD3F75' },
];

const ImperviousBuildup = () => {
  const {
    actions: { dispatchOpacity },
  } = React.useContext(AppContext);

  const onChangeCommitted = val => {
    dispatchOpacity({ type: 'SET_IMP_BUILD_OPACITY', opacity: val / 100 });
  };
  return (
    <Box>
      <Typography variant="body2">
        The HRL Imperviousness 2018 product from the Copernicus Land Monitoring
        Service (CLMS) provides a synoptic view of the impermeable cover of soil
        as well as built-up areas (new in the 2018 production). The data
        presented here provides a synoptic view of built-up areas (above-ground
        building constructions) in Copenhagen.
      </Typography>
      <Box mt={0.5}>
        <Typography variant="body2">
          &#169; European Union, Copernicus Land Monitoring Service 2020,
          European Environment Agency (EEA)
        </Typography>
      </Box>
      <Box py={1}>
        <Typography variant="subtitle1">Impervious built-up legend:</Typography>
      </Box>

      <Grid container spacing={0}>
        {legend &&
          legend.map(({ title, color }) => (
            <React.Fragment key={title}>
              <Grid item xs={6}>
                <Box
                  width={1}
                  height={6}
                  style={{
                    borderRadius: 3,
                    marginTop: 5,
                    backgroundColor: color,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ paddingLeft: 16 }}>
                  {title}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
      <Box mb={1} />
      <OpacitySlider getValue={onChangeCommitted} />
    </Box>
  );
};

export default ImperviousBuildup;
