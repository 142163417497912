import React from 'react';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';

const copenhagenJson = require('assets/geojson/copenhagen.json');

const GeoJsonCPH = ({ viewport }) => {
  const copenhagen = new GeoJsonLayer({
    id: 'geojson-copenhagen',
    data: copenhagenJson,
    stroked: true,
    filled: false,
    lineWidthScale: 20,
    getLineColor: [11, 69, 102],
    lineWidthMinPixels: 1.5,
  });

  return (
    <DeckGL controller={true} layers={[copenhagen]} viewState={viewport} />
  );
};

export default GeoJsonCPH;
