import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { clientWidth: width, clientHeight: height } = document.body;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const opacityReducer = (state, action) => {
  switch (action.type) {
    case 'SET_NDVI_OPACITY':
      return { ...state, greenness_opacity: action.opacity };
    case 'SET_LST_OPACITY':
      return { ...state, lst_opacity: action.opacity };
    case 'SET_AEROSOL_OPACITY':
      return { ...state, aerosol_opacity: action.opacity };
    case 'SET_GRASSLAND_OPACITY':
      return { ...state, grassland_opacity: action.opacity };
    case 'SET_IMP_DENSITY_OPACITY':
      return { ...state, imp_density_opacity: action.opacity };
    case 'SET_IMP_BUILD_OPACITY':
      return { ...state, build_density_opacity: action.opacity };
    case 'SET_TEMPERATURE_OPACITY':
      return { ...state, temperature_opacity: action.opacity };
    default:
      return state;
  }
};

export const opacityDefaults = {
  greenness_opacity: 1,
  lst_opacity: 1,
  aerosol_opacity: 1,
  grassland_opacity: 1,
  imp_density_opacity: 1,
  build_density_opacity: 1,
  temperature_opacity: 1,
};
