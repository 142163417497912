import axios from 'axios';

const SATLAS_URL = `https://oupqp5gj9b.execute-api.eu-central-1.amazonaws.com/development`;

export const getNdviData = async () => {
  let url = `${SATLAS_URL}/datasets?city=cph&band=ndvi&limit=20000`;
  return await axios.get(url).then(res =>
    res.data.datasets.map(set => {
      return {
        title: set.date,
        active: false,
        data: `${SATLAS_URL}/singleband/${set.city}/${set.sensor}/${set.frequency}/${set.date}/${set.band}/{z}/{x}/{y}.png?colormap=rdylgn&stretch_range=[0,1]`,
      };
    })
  );
};

export const getLstData = async () => {
  let url = `${SATLAS_URL}/datasets?city=cph&band=lst&limit=20000`;
  return await axios.get(url).then(res =>
    res.data.datasets.map(set => {
      return {
        title: set.date,
        active: false,
        data: `${SATLAS_URL}/singleband/${set.city}/${set.sensor}/${set.frequency}/${set.date}/${set.band}/{z}/{x}/{y}.png?colormap=jet&stretch_range=[10,35]`,
      };
    })
  );
};

export const getAerosolData = async () => {
  let url = `${SATLAS_URL}/datasets?city=cph&band=aerosol&limit=20000`;
  return await axios.get(url).then(res =>
    res.data.datasets.map(set => {
      return {
        title: set.date,
        active: false,
        category: set.frequency,
        data: `${SATLAS_URL}/singleband/${set.city}/${set.sensor}/${set.frequency}/${set.date}/${set.band}/{z}/{x}/{y}.png?colormap=ylorrd`,
      };
    })
  );
};

export const getOpticalData = async () => {
  let url = `${SATLAS_URL}/datasets?city=cph&band=red&limit=20000`;
  return await axios.get(url).then(res =>
    res.data.datasets.map(set => {
      return {
        title: set.date,
        active: false,
        category: set.frequency,
        data: `${SATLAS_URL}/rgb/${set.city}/${set.sensor}/${set.frequency}/${set.date}/{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=%5B0,3000%5D&g_range=%5B0,3000%5D&b_range=%5B0,3000%5D`,
      };
    })
  );
};
