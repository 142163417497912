import React from 'react';
import ButtonGroupOptical from './partials/ButtonGroupOptical';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { linkStyle } from 'styles/global';

const SatelliteImagery = () => {
  const [details, showDetails] = React.useState(false);
  const classes = linkStyle();
  return (
    <Box display="flex" flexWrap="wrap">
      <Typography variant="body2">
        Modern satellite sensors collects data in many different spectral bands
        of the electromagnetic spectrum (also called multispectral images). When
        rendered in red, blue and green (RGB), the images provide a natural
        image relatable to the human eye.{' '}
        <span
          className={classnames({
            [classes.link]: true,
            [classes.close]: details,
          })}
          onClick={() => showDetails(!details)}
        >
          {details ? 'Hide details.' : 'Read more.'}
        </span>
      </Typography>

      {details && (
        <>
          <Box mt={1}>
            <Typography variant={'body2'}>
              This collection of satellite images is based on Landsat and
              Sentinel 2 data and provides:
            </Typography>
            <Box mt={0.5}>
              <Typography variant={'body2'}>
                1. Annual cloud-free composites from 1984-2016.
              </Typography>
              <Typography variant={'body2'}>
                2. Monthly cloud-free composites from Aug-19 to Aug-20.
              </Typography>
              <Typography variant={'body2'}>
                3. Daily images from Jun-20 to Sep-20.
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <ButtonGroupOptical />
    </Box>
  );
};

export default SatelliteImagery;
