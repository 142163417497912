import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { AppContext } from 'App';

const OpticalButtonStyle = { minWidth: 72 };

const ButtonMonthly = ({handleChange}) => {
  const {
    state: { opticalData, monthNames },
  } = React.useContext(AppContext);

  const classes = buttonStyle();

  const getMontlyTitle = title => {
    const parts = title.split('-');
    return monthNames[parseInt(parts[1]) - 1] + " '" + parts[0].substring(2, 4);
  };

  return (
    <>
      <Box width={1} py={1}>
        <Typography variant="subtitle1">Select monthly data:</Typography>
      </Box>
      <Box width={1} pb={1} display="flex" flexWrap="wrap">
        {opticalData &&
          opticalData.map(
            ({ active, category, title }, i) =>
              category === 'monthly' && (
                <Box
                  key={title + i}
                  className={classnames({
                    [classes.button]: true,
                    [classes.active]: active,
                  })}
                  style={OpticalButtonStyle}
                  onClick={() => handleChange(i, true)}
                >
                  <Typography variant="body2">
                    {getMontlyTitle(title)}
                  </Typography>
                </Box>
              )
          )}
      </Box>
    </>
  );
};

export default ButtonMonthly;
