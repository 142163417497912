// pinks
// export const kommuneColors = [
//   { kommune: 'Amager', color: [105, 66, 106] },
//   { kommune: 'Indre By', color: [130, 95, 135] },
//   { kommune: 'Østerbro', color: [155, 124, 164] },
//   { kommune: 'Nørrebro', color: [181, 154, 194] },
//   { kommune: 'Brønshøj-Husum', color: [206, 183, 223] },
//   { kommune: 'Bispebjerg', color: [231, 212, 252] },
//   { kommune: 'Vanløse', color: [236, 207, 243] },
//   { kommune: 'Amager Vest', color: [241, 202, 234] },
//   { kommune: 'Valby', color: [245, 198, 225] },
//   { kommune: 'Vesterbro-Kongens Enghave', color: [250, 193, 216] },
//   { kommune: 'Frederiksberg', color: [255, 188, 207] },
// ];

// plum - purple - orange
// export const kommuneColors = [
//   { kommune: 'Amager', color: [142, 7, 77] },
//   { kommune: 'Indre By', color: [160, 48, 112] },
//   { kommune: 'Østerbro', color: [178, 89, 147] },
//   { kommune: 'Nørrebro', color: [195, 130, 182] },
//   { kommune: 'Brønshøj-Husum', color: [213, 171, 217] },
//   { kommune: 'Bispebjerg', color: [231, 212, 252] },
//   { kommune: 'Vanløse', color: [236, 194, 221] },
//   { kommune: 'Amager Vest', color: [240, 175, 190] },
//   { kommune: 'Valby', color: [245, 157, 158] },
//   { kommune: 'Vesterbro-Kongens Enghave', color: [249, 138, 127] },
//   { kommune: 'Frederiksberg', color: [254, 120, 96] },
// ];

// 11 colors
export const kommuneColors = [
  { kommune: 'Amager', color: [105, 66, 106] },
  { kommune: 'Indre By', color: [96, 70, 164] },
  { kommune: 'Østerbro', color: [231, 212, 252] },
  { kommune: 'Nørrebro', color: [255, 188, 207] },
  { kommune: 'Brønshøj-Husum', color: [142, 7, 77] },
  { kommune: 'Bispebjerg', color: [171, 139, 229] },
  { kommune: 'Vanløse', color: [66, 76, 83] },
  { kommune: 'Amager Vest', color: [210, 161, 109] },
  { kommune: 'Valby', color: [129, 114, 0] },
  { kommune: 'Vesterbro-Kongens Enghave', color: [125, 76, 5] },
  { kommune: 'Frederiksberg', color: [74, 26, 0] },
];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
