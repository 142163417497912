import React from 'react';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';
import { AppContext } from 'App';
import 'index.css';

const bydeleJson = require('assets/geojson/aggregated-lst.json');

const GeoJsonDistricts = ({ viewport }) => {
  const {
    state: { kommuneColors, kommunes },
    actions: { setKommunes },
  } = React.useContext(AppContext);

  const [hoverInfo, setHoverInfo] = React.useState();

  const handleKommune = e => {
    if (e.object === undefined) return;

    const props = e.object.properties;
    let arr = kommunes || [];
    if (arr.includes(props)) arr.splice(arr.indexOf(props), 1);
    else arr.push(props);
    setKommunes([...arr]);
  };

  const bydele = new GeoJsonLayer({
    id: 'geojson-bydele',
    data: bydeleJson,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 75],
    getFillColor: [255, 0, 0, 0],
    stroked: true,
    filled: true,
    pickable: true,
    lineWidthScale: 20,
    getLineColor: [11, 69, 102],
    lineWidthMinPixels: 0.5,
    onHover: info => setHoverInfo(info),
    onClick: handleKommune,
  });

  const selectedBydele = bydeleJson.features.map(bydele => {
    return {
      name: bydele.properties.navn,
      data: new GeoJsonLayer({
        data: bydele,
        stroked: true,
        filled: false,
        lineWidthScale: 20,
        getLineColor: kommuneColors
          .filter(({ kommune }) => kommune === bydele.properties.navn)
          .map(col => col.color)[0],
        lineWidthMinPixels: 2.5,
      }),
    };
  });

  return (
    <DeckGL
      controller={true}
      layers={[
        bydele,
        kommunes &&
          selectedBydele &&
          selectedBydele.map(({ name, data }) =>
            kommunes.map(kom => kom.navn === name && data)
          ),
      ]}
      viewState={viewport}
    >
      {hoverInfo && hoverInfo.object && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            pointerEvents: 'none',
            left: hoverInfo.x,
            top: hoverInfo.y - 32,
            fontSize: 12,
            fontWeight: 600,
            backgroundColor: '#fff',
            padding: '0px 8px 2px',
            borderRadius: 2,
            color: '#0B4566',
            textAlign: 'center',
          }}
        >
          {'Plot temperature for'}
          <br />
          {hoverInfo.object.properties.navn}
        </div>
      )}
    </DeckGL>
  );
};

export default GeoJsonDistricts;
