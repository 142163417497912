import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { format, parse } from 'date-fns';
import { AppContext } from 'App';

const ndviButtonStyle = {minWidth: 72 };
const numberStyle = {  minWidth: 12 };
const months = [6, 7, 8];

const ButtonGroupAerosol = () => {
  const {
    actions: { setAerosolData },
    state: { aerosolData, monthNames },
  } = React.useContext(AppContext);

  const [selectedMonth, selectMonth] = React.useState();

  const classes = buttonStyle();

  const handleChange = (i, clear) => {
    let obj = aerosolData;
    obj.forEach(button => (button.active = false));
    obj[i].active = !obj[i].active;
    setAerosolData([...obj]);
    clear && selectMonth();
  };

  return (
    <>
      <Box width={1} py={1}>
        <Typography variant="subtitle1">Select monthly data:</Typography>
      </Box>
      <Box width={1} pb={1} display="flex" flexWrap="wrap">
        {aerosolData &&
          aerosolData.map(
            ({ active, category, title }, i) =>
              category === 'monthly' && (
                <Box
                  key={title + i}
                  className={classnames({
                    [classes.button]: true,
                    [classes.active]: active,
                  })}
                  style={ndviButtonStyle}
                  onClick={() => handleChange(i, true)}
                >
                  <Typography variant="body2">
                    {format(parse(title, 'yyyy-MM-dd', new Date()), 'MMM yyyy')}
                  </Typography>
                </Box>
              )
          )}
      </Box>
      <Box width={1} py={1}>
        <Typography variant="subtitle1">Select daily data:</Typography>
      </Box>
      <Box width={1} pb={1} display="flex" flexWrap="wrap">
        {months &&
          months.map(month => (
            <Box
              key={month}
              className={classnames({
                [classes.semiButton]: true,
                [classes.semiActive]: month === selectedMonth,
              })}
              style={ndviButtonStyle}
              onClick={() =>
                month === selectedMonth ? selectMonth() : selectMonth(month)
              }
            >
              <Typography variant="body2">
                {monthNames[month] + ' 2019'}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box width={1} pb={selectedMonth && 1} display="flex" flexWrap="wrap">
        {aerosolData &&
          aerosolData.map(
            ({ active, category, title }, i) =>
              category === 'nrt' &&
              parseInt(title.split('-')[1]) === selectedMonth && (
                <Box
                  key={title + i}
                  className={classnames({
                    [classes.button]: true,
                    [classes.active]: active,
                  })}
                  style={numberStyle}
                  onClick={() => handleChange(i)}
                >
                  <Typography variant="body2">{title.split('-')[2]}</Typography>
                </Box>
              )
          )}
      </Box>
    </>
  );
};

export default ButtonGroupAerosol;
