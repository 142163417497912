import React from 'react';

import { AppContext } from 'App';
import { Box, IconButton } from '@material-ui/core';
import { iconStyle } from 'styles/global';

// import { ReactComponent as LeftIcon } from '@mike/mike-shared-frontend/media/icons/Left.svg';
// import { ReactComponent as RightIcon } from '@mike/mike-shared-frontend/media/icons/Right.svg';
// import { ReactComponent as UpIcon } from '@mike/mike-shared-frontend/media/icons/Up.svg';
// import { ReactComponent as DownIcon } from '@mike/mike-shared-frontend/media/icons/Down.svg';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SidebarControl = ({ mobile }) => {
  const {
    actions: { handleToggleDrawer },
    state: { openDrawer },
  } = React.useContext(AppContext);

  const classes = iconStyle();

  return (
    <Box
      flexGrow={1}
      display="flex"
      flexDirection={mobile === true ? 'row' : 'column'}
      style={{ overflowY: 'auto', height: ' 100%' }}
    >
      <Box>
        <IconButton className={classes.button} onClick={handleToggleDrawer}>
          {openDrawer === true && mobile !== true && (
            <ChevronRightIcon className={classes.icon} />
          )}
          {openDrawer === false && mobile !== true && (
            <ChevronLeftIcon className={classes.icon} />
          )}
          {openDrawer === true && mobile === true && (
            <ExpandMoreIcon className={classes.icon} />
          )}
          {openDrawer === false && mobile === true && (
            <ExpandLessIcon className={classes.icon} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default SidebarControl;
