import React from 'react';
import ReactMapGL from 'react-map-gl';

import AerosolLayer from './partials/AerosolLayer';
import GeoJsonDistricts from './partials/GeoJsonDistricts';
import GeoJsonCPH from './partials/GeoJsonCPH';
import GrasslandLayer from './partials/GrasslandLayer';
import ImperviousBuildupLayer from './partials/ImperviousBuildupLayer';
import ImperviousDensityLayer from './partials/ImperviousDensityLayer';
import LstLayer from './partials/LstLayer';
import NdviLayer from './partials/NdviLayer';
import OpticalLayer from './partials/OpticalLayer';
import TempLayer from './partials/TempLayer';

import ZoomControl from './ZoomControl';
import { AppContext } from 'App';

import 'mapbox-gl/dist/mapbox-gl.css';

const { token: accessToken } = require('config.json');

const Map = ({ mobile }) => {
  const {
    state: {
      activeProducts,
      aerosolData,
      lstData,
      ndviData,
      opticalData,
      isOpticalBasemap,
    },
  } = React.useContext(AppContext);

  const [viewport, setViewport] = React.useState({
    longitude: 12.568337,
    latitude: 55.676098,
    zoom: 11,
  });

  const setZoom = zoom => setViewport({ ...viewport, zoom });

  return (
    <ReactMapGL
      {...viewport}
      mapStyle={
        isOpticalBasemap
          ? 'mapbox://styles/mapbox/satellite-v9'
          : 'mapbox://styles/mapbox/light-v9'
      }
      width="100%"
      height="100%"
      mapboxApiAccessToken={accessToken}
      onViewportChange={setViewport}
      mapOptions={{
        customAttribution:
          '<a href="https://sentinel.esa.int/web/sentinel/home" target="blank">' +
          'Contains modified Copernicus Sentinel data ' +
          new Date().getFullYear() +
          ' ' +
          '</a>',
      }}
    >
      {activeProducts === 0 && opticalData && <OpticalLayer />}
      {activeProducts === 1 && ndviData && <NdviLayer />}
      {activeProducts === 2 && lstData && <LstLayer />}
      {activeProducts === 3 && aerosolData && <AerosolLayer />}
      {activeProducts === 4 && lstData && <GrasslandLayer />}
      {activeProducts === 5 && <ImperviousDensityLayer />}
      {activeProducts === 6 && <ImperviousBuildupLayer />}
      {activeProducts === 7 && lstData && <TempLayer />}

      {activeProducts === 7 ? (
        <GeoJsonDistricts viewport={viewport} />
      ) : (
        <GeoJsonCPH viewport={viewport} />
      )}

      {!mobile && (
        <ZoomControl setZoom={setZoom} zoom={viewport && viewport.zoom} />
      )}
    </ReactMapGL>
  );
};

export default Map;
