import React from 'react';
import ButtonGroupNdvi from './ButtonGroupNdvi';
import { Box, Typography } from '@material-ui/core';
import colorbar from 'assets/images/colorbar_ndvi.png';
import OpacitySlider from 'components/Slider/OpacitySlider';
import { AppContext } from 'App';

const legendStyle = {
  fontSize: 10,
  fontWeight: 500,
};

const values = [0.0, 0.2, 0.4, 0.6, 0.8, 1.0];

const Greenness = () => {
  const {
    actions: { dispatchOpacity },
  } = React.useContext(AppContext);

  const onChangeCommitted = val => {
    dispatchOpacity({ type: 'SET_NDVI_OPACITY', opacity: val / 100 });
  };
  return (
    <Box>
      <Typography variant="body2">
        The Normalized Difference Vegetation Index (NDVI) is a measure of
        photosynthetic activity and is used as an indicator to monitor the
        presence and greenness of vegetation. The data presented here is
        calculated from multispectral Sentinel-2 data. High values (green
        colors) represent healthy vegetation, low values (red) non-vegetation
        land cover.
      </Typography>
      <Box py={1}>
        <Typography variant="subtitle1">Select month:</Typography>
      </Box>
      <ButtonGroupNdvi />
      <Box py={1}>
        <Typography variant="subtitle1">NDVI legend:</Typography>
      </Box>

      <img
        src={colorbar}
        alt="colorbar"
        style={{ width: '100%', height: 6, borderRadius: 3 }}
      />
      <Box width={1} display="flex" justifyContent="space-between">
        {values &&
          values.map(val => (
            <Box key={val}>
              <Typography style={legendStyle}>{val}</Typography>
            </Box>
          ))}
      </Box>
      <Box mb={1} />
      <OpacitySlider getValue={onChangeCommitted} />
    </Box>
  );
};

export default Greenness;
