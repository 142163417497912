import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { buttonStyle } from 'styles/global';
import { AppContext } from 'App';

const ndviButtonStyle = {
  minWidth: 76,
};


const ButtonGroupLst = () => {
  const {
    actions: { setLstData },
    state: { lstData, monthNames },
  } = React.useContext(AppContext);

  const classes = buttonStyle();

  const handleChange = i => {
    let obj = lstData;
    obj.forEach(button => (button.active = false));
    obj[i].active = !obj[i].active;
    setLstData([...obj]);
  };

  const format = date => {
    const d = date.split('-');
    return `${d[2]} ${monthNames[parseInt(d[1]) - 1]} '${d[0].substring(2, 4)}`;
  };

  return (
    <Box width={1} pb={1} display="flex" flexWrap="wrap">
      {lstData &&
        lstData.map(({ active, title }, i) => (
          <Box
            key={title + i}
            className={classnames({
              [classes.button]: true,
              [classes.active]: active,
            })}
            style={ndviButtonStyle}
            onClick={() => handleChange(i)}
          >
            <Typography variant="body2">
              {format(title)}
              {/* {format(parse(title, 'yyyy-MM-dd', new Date()), 'dd MMM yy')} */}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default ButtonGroupLst;
