import React from 'react';
import { Grid } from '@material-ui/core';
import { Slider } from '@dhi-gras/react-components';
const OpacitySlider = ({ getValue }) => {
  const [value, setValue] = React.useState(100);

  const onChange = val => {
    setValue(val);
  };

  const onChangeCommitted = val => {
    getValue(val);
  };

  return (
    <Grid container alignItems="center">
      <Slider
        defaultValue={value}
        min={0}
        max={100}
        step={1}
        title={'Opacity:'}
        unit={'%'}
        getValue={val => onChange(val)}
        getValueCommitted={val => onChangeCommitted(val)}
      />
    </Grid>
  );
};

export default OpacitySlider;
