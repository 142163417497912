import React from 'react';
import ButtonGroupAerosol from './ButtonGroupAerosol';
import { Box, Typography } from '@material-ui/core';
import { linkStyle } from 'styles/global';
import colorbar from 'assets/images/colorbar_ylorrd.png';
import OpacitySlider from 'components/Slider/OpacitySlider';
import { AppContext } from 'App';

const AirQuality = () => {
  const {
    actions: { dispatchOpacity },
  } = React.useContext(AppContext);
  const styles = linkStyle();
  const onChangeCommitted = val => {
    dispatchOpacity({ type: 'SET_AEROSOL_OPACITY', opacity: val / 100 });
  };
  return (
    <Box display="flex" flexWrap="wrap">
      <Typography variant="body2">
        The Sentinel 5 satellite collects information and services on air
        quality, climate and the ozone layer. Derived from Sentinel 5
        observations, the data presented here provides monthly averages, as well
        as selected daily values during the summer of 2020, of the presence of
        aerosols in the atmosphere, such as dust and smoke.
      </Typography>

      <ButtonGroupAerosol />

      <Box py={1}>
        <Typography variant="subtitle1">Aerosol pressence legend:</Typography>
      </Box>
      <img
        src={colorbar}
        alt="colorbar"
        style={{ width: '100%', height: 6, borderRadius: 3 }}
      />
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td className={styles.legend} style={{ textAlign: 'left' }}>
              low
            </td>
            <td className={styles.legend} style={{ textAlign: 'right' }}>
              high
            </td>
          </tr>
        </tbody>
      </table>
      <Box mt={1} style={{ width: '100%' }}>
        <OpacitySlider getValue={onChangeCommitted} />
      </Box>
    </Box>
  );
};

export default AirQuality;
